/* Define the slide-down animation */
@keyframes slideDown {
    0% {
        height: 0px;
        visibility: hidden;
        padding-top: 0px;
        padding-bottom: 0px;
        min-height: 0px;
    }

    100% {
        padding-top: 4px;
        padding-bottom: 4px;
        min-height: 25px;
        visibility: visible;
    }
}

/* Define the slide-up animation */
@keyframes slideUp {
    0% {
        padding-top: 4px;
        padding-bottom: 4px;
        min-height: 25px;
        visibility: visible;
    }

    100% {
        height: 0px;
        visibility: hidden;
        padding-top: 0px;
        padding-bottom: 0px;
        min-height: 0px;
    }
}

/* Apply the slide-down animation */
.slide-down {
    animation: slideDown 0.2s linear both;
}

/* Apply the slide-up animation */
.slide-up {
    animation: slideUp 0.35s ease-in forwards;
}

@keyframes slide-up {
    0% {
        transform: translateY(35%);
        visibility: visible;
        opacity: 1;
    }

    100% {
        transform: translateY(0);
        visibility: hidden;
        opacity: 0;
    }
}

@keyframes slide-down {
    0% {
        transform: translateY(0);
        visibility: hidden;
        opacity: 0;
    }

    100% {
        transform: translateY(35%);
        visibility: visible;
        opacity: 1;
    }
}

.animate-slide-up {
    animation: slide-up 0.3s ease-out forwards;
}

.animate-slide-down {
    animation: slide-down 0.3s ease-out forwards
}