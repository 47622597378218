.animated-icon path {
  fill: none;
  stroke-width: 2;
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: draw-shape 1s ease forwards, draw-detail 1s ease forwards 1s;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.animated-warning path:nth-child(2) {
  stroke-width: 4; /* Make the exclamation mark thicker */
}

@keyframes draw-shape {
  0% {
    stroke-dashoffset: 100; /* Hide the shape (triangle) */
  }
  100% {
    stroke-dashoffset: 0; /* Reveal the shape (triangle) */
  }
}

@keyframes draw-detail {
  0% {
    stroke-dashoffset: 100; /* Hide the detail (exclamation mark) */
  }
  100% {
    stroke-dashoffset: 0; /* Reveal the detail (exclamation mark) */
  }
}
